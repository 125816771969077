import React from "react";

const FlagIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <defs>
      <path
        id="flag__icon__a"
        d="M35.202 14l7.63-11.445A.998.998 0 0 0 42 1H6a1 1 0 0 0-1 1v44a1 1 0 1 0 2 0V27h35a1 1 0 0 0 .832-1.554L35.202 14z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="flag__icon__b" fill="#fff">
        <use xlinkHref="#flag__icon__a" />
      </mask>
      <use fill="currentColor" fillRule="nonzero" xlinkHref="#flag__icon__a" />
      <g fill="currentColor" fillRule="nonzero" mask="url(#flag__icon__b)">
        <path d="M0 0h48v48H0z" />
      </g>
    </g>
  </svg>
);

export default FlagIcon;
