import FlagIcon from "@/components/svg/FlagIcon";
import StepIcon from "@/components/svg/StepIcon";
import PrincipleIcon from "@/components/svg/PrincipleIcon";
import MatchingIcon from "@/components/svg/MatchingIcon";
import GridIcon from "@/components/svg/GridIcon";
import * as React from 'react';
export default {
  FlagIcon,
  StepIcon,
  PrincipleIcon,
  MatchingIcon,
  GridIcon,
  React
};