import React, { useState, useMemo } from "react";
import CodeTabPane from "./CodeTabPane";
import {
  CodeLanguageType,
  codeLanguageRef,
  codeLanguages
} from "../../constants/code-languages";
import { useTabContext } from "../../context/CodeTabContext";
import styles from "./styles.module.scss";

export interface CodeTabsProps {
  defaultType?: CodeLanguageType;
  children: any;
}

function CodeTabs({ children }: CodeTabsProps) {
  if (!children) {
    console.error(
      "the CodeTabs component requires CodeTabs.Pane to be it's children"
    );
    return null;
  }

  const renderedChildren = React.Children.toArray(children).filter(
    child =>
      child.type === CodeTabPane && codeLanguages.includes(child.props.lang)
  );

  const childTypes = useMemo(() => {
    return renderedChildren.map(child => child.props.lang);
  }, []);

  const { setCurrentTab, tab } = useTabContext();
  const [activeTab, setActiveTab] = useState(() => {
    if (childTypes.includes(tab)) {
      return tab;
    }
    return childTypes[0];
  });

  React.useEffect(() => {
    if (childTypes.includes(tab)) {
      setActiveTab(tab);
    }
  }, [tab]);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        {renderedChildren.map(child => {
          const lang = child.props.lang as CodeLanguageType;
          let className = styles.header;
          if (lang === activeTab) {
            className += ` ${styles.headerActive}`;
          }
          return (
            <button
              onClick={() => {
                setCurrentTab(lang);
              }}
              key={lang}
              className={className}
            >
              {codeLanguageRef[lang].name}
            </button>
          );
        })}
      </div>
      <div>
        {renderedChildren.filter(child => child.props.lang === activeTab)}
      </div>
    </div>
  );
}

CodeTabs.Pane = CodeTabPane;

export default CodeTabs;
