import React from "react";

const GridIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <defs>
      <path
        id="grid__icon__a"
        d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm24 0h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H28a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zM4 27h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V28a1 1 0 0 1 1-1zm24 0h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H28a1 1 0 0 1-1-1V28a1 1 0 0 1 1-1z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="grid__icon__b" fill="#fff">
        <use xlinkHref="#grid__icon__a" />
      </mask>
      <use fill="currentColor" fillRule="nonzero" xlinkHref="#grid__icon__a" />
      <g fill="currentColor" fillRule="nonzero" mask="url(#grid__icon__b)">
        <path d="M0 0h48v48H0z" />
      </g>
    </g>
  </svg>
);

export default GridIcon;
