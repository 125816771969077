/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */


require("prismjs/plugins/line-numbers/prism-line-numbers.css");

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  // The lang param in the url indicates switching tab from CodeTabs component
  // we won't update scroll position to keep the position as it is
  if (location.search.indexOf("?lang=") !== -1) {
    return false;
  }

  return true;
};
