import React from "react";
import { useCopyToClipboard } from "@uxui/copy-to-clipboard-react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import {
  CodeLanguageType,
  codeLanguages,
  codeLanguageRef
} from "../../constants/code-languages";
import styles from "./styles.module.scss";
import marked from "marked";

/**
 * language available here: https://github.com/conorhastings/react-syntax-highlighter/blob/master/AVAILABLE_LANGUAGES_HLJS.MD
 * color available here: https://github.com/conorhastings/react-syntax-highlighter/blob/master/AVAILABLE_STYLES_HLJS.MD
 */
export interface CodeTabPaneProps {
  lang: CodeLanguageType;
  content: string;
}

const CodeTabPane: React.FunctionComponent<CodeTabPaneProps> = ({
  content,
  lang
}) => {
  const { copied, copy, turnOffCopied } = useCopyToClipboard();

  if (!codeLanguages.includes(lang)) {
    console.error(
      `"${lang}" is not valid value for "lang" props. A valid value should be ${codeLanguages.join(
        " | "
      )}.`
    );
    return null;
  }

  const copyTextToClipBoard = () => {
    if (typeof content === "string" && !copied) {
      copy(content.trim());
      setTimeout(() => {
        turnOffCopied();
      }, 1100);
    }
  };

  if (lang === "console") {
    return (
      <div
        className={"markdown-console " + styles.console}
        dangerouslySetInnerHTML={{ __html: marked(content.trim()) }}
      ></div>
    );
  }

  return (
    <div className={styles.pane}>
      <SyntaxHighlighter
        language={codeLanguageRef[lang].highlighter}
        showLineNumbers
        style={{
          ...tomorrowNight,
          ...{
            hljs: {
              background: "#22232a"
            }
          }
        }}
      >
        {content.trim()}
      </SyntaxHighlighter>
      <button
        title="Click to copy"
        className={styles.copyButton}
        onClick={copyTextToClipBoard}
      >
        {copied && <span>Copied</span>}
        Copy
      </button>
    </div>
  );
};

export default CodeTabPane;
