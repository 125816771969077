export const codeLanguages = <const>[
  "go",
  "javascript",
  "react",
  "php",
  "console"
];

export type CodeLanguageType = typeof codeLanguages[number];

export const codeLanguageRef: {
  [k in CodeLanguageType]: {
    name: string;
    highlighter: string;
  };
} = {
  php: { name: "PHP", highlighter: "php" },
  console: { name: "Console", highlighter: "console" },
  javascript: { name: "JavaScript", highlighter: "javascript" },
  react: { name: "React", highlighter: "javascript" },
  go: { name: "Go", highlighter: "go" }
};
