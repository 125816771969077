module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sajari Documentation","short_name":"Sajari Docs","start_url":"/","background_color":"#22232B","theme_color":"#ffffff","display":"standalone","icon":"src/images/sajari-logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"0cc70845694cc39c415820cff88d15bc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"documentation":"/workspace/src/layouts/documentation/index.tsx"},"rehypePlugins":[null],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-","showLineNumbers":true,"inlineCodeMarker":{"tsx":"tsx"},"aliases":{}}},{"resolve":"gatsby-remark-images","options":{"maxWidth":600,"disableBgImage":true,"linkImagesToOriginal":false}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"LuvfjaBDFqeiadIvy64EvgEyaKcdg3dO","trackPage":true,"delayLoad":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
