import React from "react";

const PrincipleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <defs>
      <path
        id="principle__icon__a"
        d="M29.864 41.312l-15.756-27.29H2a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h16.677L18.714 6l.012.021h.025l.057.14 16.084 27.86H46a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H30.323l-.037.022-.012-.022h-.131l-.28-.709zm1.094-20.476l-4.619-8 3.525-6.105.279-.71h.13L30.287 6l.037.021H46a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H34.892l-3.934 6.815zm-8.083 14l-4.067 7.045-.057.14h-.025l-.012.022-.037-.022H2a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h12.108l4.148-7.185 4.619 8z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="principle__icon__b" fill="#fff">
        <use xlinkHref="#principle__icon__a" />
      </mask>
      <use fill="currentColor" xlinkHref="#principle__icon__a" />
      <g fill="currentColor" fillRule="nonzero" mask="url(#principle__icon__b)">
        <path d="M0 0h48v48H0z" />
      </g>
    </g>
  </svg>
);

export default PrincipleIcon;
