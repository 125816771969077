import * as React from "react";
import { CodeLanguageType } from "../constants/code-languages";
import { codeLanguages } from "../constants/code-languages";
import { Location, LocationContext } from "@reach/router";
import queryString from "query-string";

interface CodeTabContextType {
  tab: CodeLanguageType;
  setCurrentTab: (tab: CodeLanguageType) => void;
}

const noop = () => void 0;

export const CodeTabContext = React.createContext<CodeTabContextType>({
  tab: codeLanguages[0],
  setCurrentTab: noop
});

export const useTabContext = (): CodeTabContextType => {
  return React.useContext(CodeTabContext);
};

const LastCodeTab = "sajari:last-code-tab";

const CodeTabProviderInner: React.FunctionComponent<{
  locationContext: LocationContext;
}> = ({ locationContext, children }) => {
  const {
    location: { pathname, search },
    navigate
  } = locationContext;

  const { lang } = queryString.parse(search);
  let lastLanTab = lang as CodeLanguageType;
  if (typeof window !== "undefined") {
    lastLanTab = window.localStorage.getItem(LastCodeTab) as CodeLanguageType;
  }

  if (!codeLanguages.includes(lastLanTab)) {
    lastLanTab = codeLanguages[0];
  }

  const [tab, setTab] = React.useState<CodeLanguageType>(lastLanTab);

  const setCurrentTab = (tab: CodeLanguageType) => {
    navigate(`${pathname}?lang=${tab}`, { replace: false });
    if (typeof window !== "undefined") {
      window.localStorage.setItem(LastCodeTab, tab);
    }
    setTab(tab);
  };

  return (
    <CodeTabContext.Provider value={{ tab, setCurrentTab }}>
      {children}
    </CodeTabContext.Provider>
  );
};

export const CodeTabProvider: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <Location>
      {locationContext => (
        <CodeTabProviderInner locationContext={locationContext}>
          {children}
        </CodeTabProviderInner>
      )}
    </Location>
  );
};
