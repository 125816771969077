import React from "react";

const StepIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <defs>
      <path
        id="step__icon__a"
        d="M12.96 17c2.173 1.701 3.29 4.471 3.29 8.25 0 2.972.735 5.06 2.263 6.319A6.001 6.001 0 0 1 29.917 33h6.166A6.005 6.005 0 0 1 41 28.083v-6.166A6.005 6.005 0 0 1 36.083 17h-6.166a6.002 6.002 0 0 1-11.834 0h-5.122zm-1.46 1.403A6.001 6.001 0 0 1 0 16a6 6 0 0 1 11.917-1h6.166a6.002 6.002 0 0 1 11.834 0h6.166A6.002 6.002 0 0 1 48 16a6.002 6.002 0 0 1-5 5.917v6.166A6.002 6.002 0 0 1 42 40a6.002 6.002 0 0 1-5.917-5h-6.166a6.002 6.002 0 0 1-11.908-1.338c-2.478-1.54-3.759-4.371-3.759-8.412 0-3.27-.894-5.53-2.75-6.847z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="step__icon__b" fill="#fff">
        <use xlinkHref="#step__icon__a" />
      </mask>
      <use fill="currentColor" xlinkHref="#step__icon__a" />
      <g fill="currentColor" fillRule="nonzero" mask="url(#step__icon__b)">
        <path d="M0 0h48v48H0z" />
      </g>
    </g>
  </svg>
);

export default StepIcon;
