import React from "react";

const MatchingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <defs>
      <path
        id="matching__icon__a"
        d="M5 3h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zm9.047 23.094l8.707 15.853A2 2 0 0 1 21 44.91H4.038a2 2 0 0 1-1.774-2.924l8.256-15.853a2 2 0 0 1 3.527-.04zM27 25h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H27a2 2 0 0 1-2-2V27a2 2 0 0 1 2-2zm8.038-2c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="matching__icon__b" fill="#fff">
        <use xlinkHref="#matching__icon__a" />
      </mask>
      <use fill="currentColor" xlinkHref="#matching__icon__a" />
      <g fill="currentColor" fillRule="nonzero" mask="url(#matching__icon__b)">
        <path d="M0 0h48v48H0z" />
      </g>
    </g>
  </svg>
);

export default MatchingIcon;
