// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-documentation-index-tsx": () => import("./../../../src/layouts/documentation/index.tsx" /* webpackChunkName: "component---src-layouts-documentation-index-tsx" */),
  "component---src-layouts-home-index-tsx": () => import("./../../../src/layouts/home/index.tsx" /* webpackChunkName: "component---src-layouts-home-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-search-results-tsx": () => import("./../../../src/pages/search-results.tsx" /* webpackChunkName: "component---src-pages-search-results-tsx" */)
}

